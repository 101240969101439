html, body, #root, #app {
  height: 100vh;
}

body {
  /* overflow: hidden; */
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  padding: 0;
  margin: 0;
}

body, dd, div, dl, dt, fieldset, form, h1, h2, h3, h4, h5, h6, input, li, ol, p, pre, td, textarea, th, ul {
  margin: 0;
  padding: 0;
}

.cm-theme, .cm-theme-light, .cm-theme-dark {
  height: 100%;
  font-size: 15px;
}

.CodeMirror * {
  font-family: monospace;
}

.dark {
  --background-color: #1C2333;
  --outline-color: #4E5569;
  --foreground-color: #F5F9FC;
  --primary-color: cyan;
  --positive-color: #009118;
  --negative-color: #E52222;
}

.light {
  --background-color: #FCFCFC;
  --outline-color: #AFB1B3;
  --foreground-color: #07080A;
  --primary-color: darkcyan;
  --positive-color: #00A11B;
  --negative-color: #FA4B4B;
}

#app {
  background: var(--background-color);
  color: var(--foreground-color);
}

#split {
  background: var(--outline-color);
  color: var(--foreground-color);
}