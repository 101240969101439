.header {
    height: 60px;
    width: 100vw;
    position: relative;
    display: block;
}

.header h1 {
    position: absolute;
    top: 14px;
    left: 10px;
    z-index: 800;
    transition: all .15s;
}

.header .title {
    position: absolute;
    top: -9000em;
}

.actions {
    height: 60px;
    font-size: 13px;
    position: relative;
    z-index: 300;
    margin-left: 80px;
    display: grid;
    grid-template-columns: auto auto;
}

.actions .actionCont {
    display: flex;
}

.actions .actionItem {
    position: relative;
    margin-right: 1px;
}

.actions button {
    display: flex;
    outline: none;
    text-decoration: none;
    width: 80px;
    align-items: center;
    justify-content: center;
    color: inherit;
    opacity: 0.8;
    position: relative;
    height: 60px;
    background-color: transparent;
    border: 0px;
    border-bottom: 2px solid transparent;
    transition: all .15s;
}

.actions button:hover {
    opacity: 1;
    border-bottom: 2px solid var(--primary-color);
}

.actions svg {
    position: relative;
    height: 16px;
    left: -5px;
}