.grid {
    display: grid;
    position: relative;
}

.grid-col {
    height: calc(100vh - 60px);
    width: 100%;
    grid-template-columns: 1fr 3px 1fr;
}

.grid-row {
    height: calc(100vh - 60px);
    width: 100%;
    grid-template-rows: 1fr 3px 1fr;
}

.gutter-col {
    grid-row: 1/-1;
    cursor: col-resize;
    grid-column: 2;
}

.editor {
    overflow: auto;
    height: 100%;
}

.gutter-row {
    grid-column: 1/-1;
    cursor: row-resize;
    grid-row: 2;
}

.gutter {
    position: relative;
}

.gutter:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
}

.gutter-col:after {
    height: 100%;
    width: 10px;
    left: -5px;
}

.gutter-row:after {
    height: 10px;
    width: 100%;
    top: -5px;
}

.gutter:hover,
.gutter:active {
    background-color: var(--primary-color);
    transition: background-color 0.3s ease-in-out;
}

.panel {
    position: relative;
    overflow: auto;
    height: 100%;
}

.windowLabelCont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
}

.windowLabel {
    font-size: 12px;
    z-index: 30;
    color: inherit;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.panel button {
    display: flex;
    align-items: center;
    opacity: 0;
    font-size: 12px;
    background: none !important;
    border: none;
    padding: 0 !important;
    color: inherit;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.panel button svg {
    margin-right: 5px;
}

.panel:hover button {
    opacity: 1;
}

.panel button:hover {
    color: var(--primary-color);
}

.CodeMirror {
    width: 100%;
    height: calc(100% - 40px);
    margin-top: 40px;
}