html, body, #root, #app {
  height: 100vh;
}

body {
  /* overflow: hidden; */
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  padding: 0;
  margin: 0;
}

body, dd, div, dl, dt, fieldset, form, h1, h2, h3, h4, h5, h6, input, li, ol, p, pre, td, textarea, th, ul {
  margin: 0;
  padding: 0;
}

.cm-theme, .cm-theme-light, .cm-theme-dark {
  height: 100%;
  font-size: 15px;
}

.CodeMirror * {
  font-family: monospace;
}

.dark {
  --background-color: #1C2333;
  --outline-color: #4E5569;
  --foreground-color: #F5F9FC;
  --primary-color: cyan;
  --positive-color: #009118;
  --negative-color: #E52222;
}

.light {
  --background-color: #FCFCFC;
  --outline-color: #AFB1B3;
  --foreground-color: #07080A;
  --primary-color: darkcyan;
  --positive-color: #00A11B;
  --negative-color: #FA4B4B;
}

#app {
  background: var(--background-color);
  color: var(--foreground-color);
}

#split {
  background: var(--outline-color);
  color: var(--foreground-color);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

textarea {
  outline: 0!important;
}

#terminal .cm-gutters {
  visibility: hidden;
}
.Header_header__2xfuR {
    height: 60px;
    width: 100vw;
    position: relative;
    display: block;
}

.Header_header__2xfuR h1 {
    position: absolute;
    top: 14px;
    left: 10px;
    z-index: 800;
    transition: all .15s;
}

.Header_header__2xfuR .Header_title__cW3ln {
    position: absolute;
    top: -9000em;
}

.Header_actions__1uxXH {
    height: 60px;
    font-size: 13px;
    position: relative;
    z-index: 300;
    margin-left: 80px;
    display: grid;
    grid-template-columns: auto auto;
}

.Header_actions__1uxXH .Header_actionCont__1_Yji {
    display: flex;
}

.Header_actions__1uxXH .Header_actionItem__3wfN5 {
    position: relative;
    margin-right: 1px;
}

.Header_actions__1uxXH button {
    display: flex;
    outline: none;
    text-decoration: none;
    width: 80px;
    align-items: center;
    justify-content: center;
    color: inherit;
    opacity: 0.8;
    position: relative;
    height: 60px;
    background-color: transparent;
    border: 0px;
    border-bottom: 2px solid transparent;
    transition: all .15s;
}

.Header_actions__1uxXH button:hover {
    opacity: 1;
    border-bottom: 2px solid var(--primary-color);
}

.Header_actions__1uxXH svg {
    position: relative;
    height: 16px;
    left: -5px;
}
.Logo_logo__QzK70 {
    fill: var(--primary-color);
}
.Workspace_grid__1m4EP {
    display: grid;
    position: relative;
}

.Workspace_grid-col__bR0Dt {
    height: calc(100vh - 60px);
    width: 100%;
    grid-template-columns: 1fr 3px 1fr;
}

.Workspace_grid-row__1u63W {
    height: calc(100vh - 60px);
    width: 100%;
    grid-template-rows: 1fr 3px 1fr;
}

.Workspace_gutter-col__1I51l {
    grid-row: 1/-1;
    cursor: col-resize;
    grid-column: 2;
}

.Workspace_editor__2BKNi {
    overflow: auto;
    height: 100%;
}

.Workspace_gutter-row__2Cw2J {
    grid-column: 1/-1;
    cursor: row-resize;
    grid-row: 2;
}

.Workspace_gutter__2EXl6 {
    position: relative;
}

.Workspace_gutter__2EXl6:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
}

.Workspace_gutter-col__1I51l:after {
    height: 100%;
    width: 10px;
    left: -5px;
}

.Workspace_gutter-row__2Cw2J:after {
    height: 10px;
    width: 100%;
    top: -5px;
}

.Workspace_gutter__2EXl6:hover,
.Workspace_gutter__2EXl6:active {
    background-color: var(--primary-color);
    transition: background-color 0.3s ease-in-out;
}

.Workspace_panel__1OiOu {
    position: relative;
    overflow: auto;
    height: 100%;
}

.Workspace_windowLabelCont__3qqfw {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
}

.Workspace_windowLabel__3EbGZ {
    font-size: 12px;
    z-index: 30;
    color: inherit;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.Workspace_panel__1OiOu button {
    display: flex;
    align-items: center;
    opacity: 0;
    font-size: 12px;
    background: none !important;
    border: none;
    padding: 0 !important;
    color: inherit;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.Workspace_panel__1OiOu button svg {
    margin-right: 5px;
}

.Workspace_panel__1OiOu:hover button {
    opacity: 1;
}

.Workspace_panel__1OiOu button:hover {
    color: var(--primary-color);
}

.Workspace_CodeMirror__2o2g8 {
    width: 100%;
    height: calc(100% - 40px);
    margin-top: 40px;
}
